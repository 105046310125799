import React, {useContext, useEffect, useState} from "react"
import { httpApiGateway } from "../adapters/secondary/httpEshopApi";
import { CategoryTree, StaticContext } from "../domain/interfaces";
import Page from "../templates/page"

const ApiGateway = new httpApiGateway()

export default function Home() {

  const api = new httpApiGateway()
  const [context, setContext] = useState<StaticContext | null>(null)

  useEffect(() => {
    api.retrieveStaticContext().then(setContext)
  }, []) // given empty array useEffect run only once

  return context && (
    <Page pageContext={context}> 
    <div className="w-full max-w-screen-md mt-10 mx-auto blog-article">
    <div className="w-full pb-20">
    <h1 className=""> Conditions générales de ventes </h1>
    <p>
        CHAMANTINI'S PAPER BEADS...est une entreprise individuelle, qui fabrique de façon artisanale, et vend des 
        bijoux, tabliers et divers accessoires de mode. Les bijoux sont montés avec des perles en papier fait 
        manuellement par Chamantini's paper beads à base de papier ( prospectus et autres) qui tend à recycler dans le 
        but de réduire des déchets 
    </p>
    <p>
        Les présentes conditions générales de ventes (CGV)  représentent l'ensemble des stipulations émise par  
        CHAMANTINI'S PAPER BEADS dont le siège social se situe au Tampon, île de la Réunion, immatriculée 
        au Répertoire de la Chambre des Métiers et de l'Artisanat de Saint-Pierre de la Réunion en date du 1er Octobre 
        2016 . SIRET : 8228324600014, ci-après dénommé «  CHAMANTINI'S » ou « Je », à destination de tout 
        acquéreur ( soit professionnel, non-professionnel ou consommateur) de ses produits en vente sur le site chamantini.re   , 
        ci-après dénommé « le client » ou « l'acheteur ». 
    </p>
    <p>
        Les présentes conditions de vente (CGV) s’appliquent à la vente de tous les produits du site, pris ensemble ou 
        séparément, quelle qu'en soit la quantité.  <br/>
        Au moment de passer sa commande, le client reconnaît avoir prit connaissance des conditions générales de 
        ventes énoncées ici et déclare formellement les accepter sans réserve. 
        Ou avant de passer commande,  le client doit prendre connaissance des CGV(dispositions suivantes) et les 
        accepter ( en cochant la case « lu et accepté les CGV » ). 
    </p>
    <p>
        CHAMANTINI'S se réserve le droit d'adapter et de modifier à tout moment les présentes CGV. Les informations sont présentés en français.
    </p>
    <h3> HERBERGEUR </h3>
    <p>
        Le site Chamantini www.chamantini.re est hébergé par la sociéte LENSELLE Nicolas (atelierdunumerique.io)
    </p>
    <h3> INSCRIPTION ET COMPTE CLIENT </h3>
    <p>
        Pour pouvoir passer commande, le client doit s'inscrire en créant un compte sur le site ( rubrique « mon compte ») <br/>
        Lors de la création du compte, le client doit s'assurer de l'exactitude des données qu'il fournit, et il est tenu 
        de  mettre à jour ses informations personnelles.  <br/>
        En cas d'erreur de coordonnées de la part du client ( adresse, rue, immeuble, pays …) CHAMANTINI'S n'est pas 
        responsable en cas d'impossibilité de livrer des produits.  <br/>
        CHAMANTINI'S se réserve le droit de supprimer le compte du client à tout moment et pour tout motif.
    </p>
    <h3> GARANTIES ET DISPONIBILITE DES PRODUITS  </h3>
    <p>
        Étant donné que la grande majorité des produits sont des pièces uniques ou des séries limitées, des erreurs ou 
        des modifications peuvent survenir sur la disponibilité des produits, le client en sera avertit par courriel. 
        Par conséquent, en cas d'annulation d'une commande d'un produit dû à l’épuisement du stock, ou dû au fait que 
        2 clients commandent le même produit en même temps, CHAMANTINI'S ne sera en aucun cas tenu pour 
        responsable. 
    </p>
    <p>
        Dans le cas ou un produit est épuisé ou indisponible, la commande sera annulée et pourra être remboursée.
    </p>
    <p>
        Les photos peuvent varier en fonction de la luminosité, le client doit se reporter aux descriptifs des produits avant 
        de passer commande. 
    </p>
    <p>
        CHAMANTINI'S s'engage à rembourser ou à échanger (au choix du client) les produits défectueux ou ne 
        correspondant pas à sa commande. Frais d'envoi remboursés sur la base du tarif facturé sur présentation des 
        justificatifs . 
    </p>
    <p>
        Le remboursement se fera à la réception de la marchandise par le vendeur (Chamantini's) et suivant la 
        constatation du vendeur du défaut, et sera remboursés dans les meilleurs délais ( 21 jours). 
    </p>
    <p>
        Les produits abîmés, incomplets, salis, endommagés ne seront ni repris ni remboursés.
    </p>
    <p>
        Il est indispensable d'envoyer une photo pour tous produits reçus défectueux ou cassés. 
    </p>
    <p> 
        Les produits ne sont pas garantis contre l'usure normale due à une utilisation régulière ou à une mauvaise 
        manipulation du produits ( machine à laver, produits chimiques, parfum,.....) 
    </p>
    <p>
        Les produits demeurent la propriété de CHAMANTINI'S jusqu'au règlement complet de la commande. 
    </p>
    <h3> COMMANDES </h3>
    <p>
        Les commandes sont prises en compte dans l'ordre chronologique de paiement. 
        Elles sont préparées sous 2 jours ouvrées. Elles sont expédiées les mercredis et samedis matin. 
    </p>
    <p>
        Pour toutes commandes personnalisées, le client doit régler 50% du montant lors de sa commande initiale et il 
        doit solder cette commande avant son envoi ou régler la totalité de sa commande.
    </p>
    <p>
        Le client reçoit par message électronique la confirmation de sa commande. 
        CHAMANTINI'S recommande au client de conserver une trace sur papier ou support informatique fiable, 
        les données concernant sa commande. 
    </p>
    <p>
        CHAMANTINI'S se réserve le droit de refuser ou d'annuler la ou les commandes d'un client avec lequel elle 
        a déjà eu un litige sur une précédente commande ou en cas de suspicion de fraude. 
    </p>
    <p>
        En cas d'impossibilité de finaliser une commande ( rupture de stock, problème de réapprovisionnement  
        rupture du réseau internet du client ou de Chamantini's , problème technique sur le site ou autres problème 
        concernant la commande reçu ..) la responsabilité de CHAMANTINI'S ne saurait être engagée. 
    </p>
    <p>
        La validation de la commande sera effective qu'après réception du paiement total de la commande, frais 
        d'envoi y compris ( paiement en ligne par CB) 
    </p>
    <p>
        Le client a la possibilité d’annuler sa commande à condition que celle-ci ne soit pas encore expédiée. 
    </p>
    <p>
        Les commandes personnalisées ne peuvent pas être annulées ni remboursées. 
    </p>
    <p>
        La confirmation de la commande entraîne l’acceptation des présentes conditions de vente, la reconnaissance d’en 
        avoir parfaite connaissance et la renonciation par le client de faire prévaloir des propres conditions d’achat ou 
        tout autres conditions. 
    </p>
    <h3>  PRIX ET MODALITES DE PAIEMENT  </h3>
    <p> Les prix sont indiqués en Euros toutes taxes comprises, hors frais de douane, de livraison et autres taxes qui 
        restent à la charge du client. 
    </p>
    <p> CHAMANTIN'S se réserve le droit de modifier ces prix à tout moment </p>
    <p>En cas de défaut de paiement, CHAMANTINI'S se réserve le droit d'interrompre momentanément ou 
        définitivement l’accès au service pour le client concerné.  
    </p>
    <p> Les produits sont payables comptant lors de la commande dans son intégralité (frais d'envoi y compris), en CB, 
        via SUMUP
    </p>
    <h3>   LIVRAISON ET MODALITES  </h3>
    <p>Les produits achetés sur le site sont livrés à l'adresse indiquée par le client lors de sa commande ou de son 
        inscription, il est donc tenu responsable de s'assurer que les informations communiqués a CHAMANTINI'S sont 
        exactes, à jour et complètes (tel que : Nom prénom  bien orthographié,  n° de rue , n° de bâtiment, d'escalier, 
        code d’accès,  ville, lieu-dit, département, …). 
        En cas de non-réception du ou des produits dû à une erreur concernant l'adresse de livraison (erronée ou 
        incomplète), CHAMANTINI’S ne sera en aucun cas tenu pour responsable du retard, du retour ou de la 
        non-livraison de la commande, et par conséquent en cas de retour des produits CHAMANTINI'S sera en droit de 
        réclamer au client le règlement des nouveaux frais de livraison. 
    </p>
    <p>
        La livraison est gratuite pour la Réunion et elle est partiellement offerte pour toutes les autres destinations. 
        Les frais d'envoi varient selon la destination ; le poids et la valeur de votre commande, votre panier  indiquera les modes de livraison adaptés à votre commande et son coût. 
    </p>
    <p>
        Les expéditions se font les mercredi et les samedi matin. Les commandes passées du samedi au mardi 
        avant 17h00 seront expédiées le mercredi. Les commandes passées du mercredi au vendredi avant 
        17h00 seront expédiées le samedi matin.
    </p>
    <p>
    Les délais de livraison sont données à titre indicatifs. Elles peuvent parfois être rallongés pour de 
    multiples raisons telles que grève des services postaux, surcharge de travail, périodes festives, grève 
    de divers transporteurs, intempéries, erreur de routage, et tout autre élément perturbateur indépendant 
    de ma volonté. 
    </p>
    <p>Les retards de livraison ne peuvent donner lieu à des dommages et intérêts, à retenue, remboursement, ni à 
        l’annulation des commandes en cours. 
    </p>
    <p>En cas de non-livraison, toute réclamation passée au-delà de 30 jours (à compter de la date d’expédition) ne sera 
        pas prise en compte. 
    </p>
    <p>A la livraison le client doit vérifier l’état de l'emballage, des produits, s’il constate des dommages, il est tenu de 
        le signaler au transporteur (sur le bon de livraison : constat des dégâts écrit de manière précise et complète, et 
        daté et signé). Il est également tenu d'en informer CHAMANTINI'S. 
        Dans le cas où le colis est abîmé ou est reconditionné par la poste, le client peut le refuser. Dans ce cas, il doit faire 
        une réclamation au bureau de poste de sa région et de me faire part de son refus; photos et réclamation faite à  
        l'appui. Si le client accepte le colis abîmé ou reconditionné, il en assumera l’entière responsabilité et n'aura 
        aucun recours possible, ni remboursement. 
    </p>
    <p>Les frais d’expéditions, les frais de douane ou autre taxe locale sont à la charge du client </p>
    <p>Si le client opte pour une livraison en lettre suivi sans signature, le colis est déposé dans sa boite aux lettres sans 
        signature. En cas de perte, vol, dégradation quelconque ou non remis, il n'y a pas de recours possible auprès de la 
        poste et par conséquent pas d’indemnisation possible. 
    </p>
    <p>Les frais de livraison sont calculés sur une base forfaitaire, selon le poids et la valeur du produit </p>
    <p>CHAMANTINI'S se réserve le droit de modifier les frais de livraison à tout moment selon l’évolution des tarifs 
        de la poste. 
    </p>
    <p>
    Pour les envois en lettre suivi, lettre ou paquet recommandée, à l'expédition vous recevrez une 
        confirmation d'envoi avec votre numéro de suivi. <br/>
            Vous pourrez suive l'acheminement de celui-ci sur le site officiel de la Poste : <br/>
        <a>http://www.csuivi.courrier.laposte.fr/suivi </a>
        
        
        Attention : Avec le mode de livraison courrier suivi sans signature, celui-ci est déposé dans votre boite 
        aux lettres, sans signature. En cas de perte, vol, colis endommagé ou non remis, il n'y à pas de recours 
        possible auprès de la Poste, et par conséquent pas d'indemnisation possible. Mode de livraison 
        uniquement pour les commandes d'une valeur inférieur à 100 euros. <br/>
        
        Pour les commandes de 100 euros et plus la livraison se fera en courrier recommandé car ce mode de 
        livraison est assuré. 
    </p>
    <h3>RETACTATION ET RETOUR  </h3>
    <p>Conformément à l’article L.221-18 t suivant le code de la consommation, le client dispose d’un délai de 
        rétractation de 14 jours francs à compter de la réception de la marchandise pour exercer son droit de rétractation. 
    </p>
    <p>Le droit de rétractation peut se faire en ligne auprès de CHAMANTINI’S à l’adresse suivante : 
        coco.chamantini@gmail.com, 
        Ou en remplissant un formulaire de rétractation (lien pr téléchargé) ou faire une déclaration dénuée de sens 
        exprimant sa volonté de se rétracter et notifier avant le délai précité, à l’adresse postale suivante : 
    </p>
    <p>CHAMANTINI’S PAPER BEADS 200, Chemin Epidor Hoarau, 97430 LE TAMPON – ILE DE LA REUNION </p>
    <p>Les commandes personnalisées ou sur mesure ne bénéficient pas du droit de rétractation, conformément à 
        l'article L121-21-8 du code de la consommation. 
    </p>
    <p>Il est recommandé au client de retourner les produits en suivi, car seul le numéro de suivi peut servir de preuve 
        de réception des produits retournés, en cas de perte du colis, aucun remboursement ne sera effectué sans cette 
        preuve de dépôt. 
    </p>
    <p>Les frais de retours restent à la charge du client. Le transport du produit engage la responsabilité du client </p>
    <p>Le remboursement ne pourra être pris en compte qu'après réception et le contrôle des produits retournés à 
        CHAMANTINI'S à l'adresse ci-dessus.
    </p>
    <p>Les produits retournés doivent être en parfait état, complets et ne pas avoir été utilisé, être renvoyés, bien protégés
        dans son emballage d'origine accompagnés de la facture et du bon de retour, cela dans les délais impartis soit 14 
        jours après que vous aurez communiqué à CHAMANTINI'S par écrit votre décision de rétractation.
    </p>
    <p>A la réception du colis, CHAMANTINI’S jugera du parfait état du produit retourné. Les produits retournés 
        abîmés, incomplets, endommagés ou salis par le client et donc rendus impropres à la revente ne seront pas 
        remboursés. 
        Si le retour est refusé par CHAMANTINI’S, les produits seront alors retournés au client aux frais de 
        Chamantini’s sans que le client puisse exiger une quelconque compensation ou droit à remboursement. 
    </p>
    <p>Le remboursement sera effectué dans un délai de 30 jours, par divers moyens </p>
    <p>CHAMANTINI'S n'accepte pas les échanges </p>

    <h3>DIVERS ET DROIT APPLICABLE </h3>
    <p>Les présentes conditions de vente sont soumises à la loi française. 
    </p>
    <p>
        En cas de litige, vous devez vous adressez à CHAMANTINI'S PAPER BEADS pour trouvezr une solution 
        amiable, à défaut le code du commerce et la législation en vigueur s'applique via les tribunaux français.                         
    </p>
    <p>Il est de la responsabilité du client de vérifier si les produits qu'il a commandé respecte la législation du pays 
        dans lequel les produits sont livrés. 
    </p>
    <p>La responsabilité de CHAMANTINI'S ne saurait être engagée, en cas de mauvaise utilisation des produits, 
        négligence, défaut d’entretien et en cas d’usure normale, d’accident dû à divers produits chimiques.
    </p>
    <p>Pour tout dommage résultant de l'utilisation du réseau internet tel que piratage informatique, perte de           
        données, virus, intrusion, rupture de service, ou autres problèmes involontaires CHAMANTINI'S ne sera en 
        aucun cas tenu pour responsable. 
    </p>
    <h3> COOKIE </h3>
    <p>
        Un cookie est une information (identifiant alphanumérique) conservée sur votre ordinateur par un site web que vous visitez. CHAMANTINI peut utiliser des cookies sur son site. Ces fichiers sont stockés sur l’ordinateur, le téléphone mobile ou la tablette numérique de l’utilisateur et peuvent enregistrer des informations relatives à la navigation de l’utilisateur sur de notre site et vous proposer des services personnalisés.
        Nous utilisons des cookies pour les besoins de la navigation sur notre site internet, afin de mémoriser vos préférences, de vous donner une meilleure expérience client et optimiser notre site internet en fournissant un contenu adapté à vos besoins.
        Un cookie ne nous permet pas de vous identifier.
        Pour vous opposer ou paramétrer l’enregistrement de nos cookies en configurant votre ordinateur vous pouvez consulter les modalités détaillées sur le site : http://www.cnil.fr/vos-droits En utilisant le site www.chamantini.re, vous nous autorisez à collecter, enregistrer, conserver, utiliser et/ou transférer les données personnelles vous concernant conformément à la présente politique de confidentialité.
        CHAMANTINI s’engage à préserver la confidentialité des informations personnelles éventuellement fournies en ligne par l’internaute. Les informations recueillies font l’objet d’un traitement informatique destiné à l’envoi d’une newsletter et/ou à la gestion de la commande de l’Acheteur. Les destinataires des données sont CHAMANTINI et ses prestataires. Conformément à la réglementation en vigueur, l’utilisateur dispose d’un droit d’accès, de rectification, d’opposition et de suppression de ses informations personnelles auprès de CHAMANTINI en s’adressant au service client par e-mail à coco.chamantini@gmail.com ou par courrier à l’adresse suivante :
        CHAMANTINI'S PAPER BEADS 200, CHEMIN EPIDOR HOAREAU 97430 LE TAMPON  - ILE DE LA REUNION
        La collecte d’informations est déclarée auprès de la CNIL sous le n°1580978
        Nous vous invitons à consulter notre Charte Données personnelles et Cookies pour plus d’informations.
    </p>
    <h3> PROPRIETE INTELECTUELLE et PROTECTION DES DONNEES PERSONNELLES </h3>
    <p>
    Tous les éléments du site chamantini.re sont et restent la propriété intellectuelle et exclusive de CHAMANTINI'S PAPER BEADS.
    Nul n'est autorisé à reproduire, exploiter, rediffuser, ou utiliser à quelque titre que ce soit, même partiellement, des éléments du site qu'ils soient logiciels, visuels ou sonores.
    Tout lien simple ou par hypertexte est strictement interdit sans un accord écrit exprès de CHAMANTINI'S PAPER BEADS.
    </p>
    <p>
        La société CHAMANTINI'S PAPER BEADS se réserve le droit de collecter les informations nominatives et les données personnelles vous concernant. Elles sont nécessaires à la gestion de votre commande, ainsi qu'à l'amélioration des services et des informations que nous vous adressons.   Elles peuvent aussi être transmises aux sociétés qui contribuent à ces relations, telles que celles chargées de l'exécution des services et commandes pour leur gestion, exécution, traitement et paiement.   Ces informations et données sont également conservées à des fins de sécurité, afin de respecter les obligations légales et réglementaires.  
        Conformément à la loi du 6 janvier 1978, vous disposez d'un droit d'accès, de rectification et d'opposition aux informations nominatives et aux données personnelles vous concernant. Vous pouvez demander la rectification ou la suppression d'informations vous concernant en contactant CHAMANTINI'S PAPER BEADS.
    </p>
    <h3>  RECLAMATIONS ET SAV  </h3>
    <p>
        Pour toute réclamation et le SAV, le client doit s'adresser : <br/>
        Par courrier : <br/>
        CHAMANTINI'S PAPER BEADS <br/>
        200, CHEMIN EPIDOR HOAREAU <br/>
        97430 LE TAMPON  - ILE DE LA REUNION <br/>
        Par courrier électronique : 
        coco.chamantini@gmail.com 
    </p>
    </div>
    </div>
    </Page>
  )

}
